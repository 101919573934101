import { httpopen } from './configOpen'
import { http } from './config'

import authHeader from './auth-header';

export default {

    enviar: (data) => {
        //let formData = new FormData();
        //formData.append("foto", file);
        //documento.file = file;
        //formData.append("documento", documento);

        const user = JSON.parse(sessionStorage.getItem('user'))

        const headers = {  
        "Content-Type": "multipart/form-data; ; charset=utf-8",
        'Authorization': 'Bearer ' + user.accessToken
        }


        http.defaults.timeout = 8000;

        return http.post('documento.cadastro', data, {headers: headers})

    },
    

    deleta: (id) => {
        return http.get('documento.delete/'+ id,  {headers: authHeader()})
    },

    listadoc: () => {
        return http.get('documento.lista', {headers: authHeader()})
    },

    listahistorico: () => {
        return http.get('historico.lista', {headers: authHeader()})
    },

    listahistoricoIdDoc: (id) => {
        return http.get('historico.documento/' + id, {headers: authHeader()})
    },

    download: (id) => {
        const params = new URLSearchParams([['id', id]])
        const user = JSON.parse(sessionStorage.getItem('user'))
        const headers = {  
            "Content-Type": "multipart/form-data; charset=ISO-8859-1",
            'Authorization': 'Bearer ' + user.accessToken
            }


        return httpopen.get('documento.download', { params }, {headers: headers, responseType: 'blob' })

        //return http.get('download', {headers: authHeader()})
    }


    

}
