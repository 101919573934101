<template>

  <v-container
    fluid
    tag="section">
    <base-material-card
    icon="mdi-history"
    title="Histórico"
    class="px-5 py-3">

                    

    <v-card>
      <v-card-title>
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Pesquisa"
        single-line
        hide-details
        ></v-text-field>
      </v-card-title>


      <v-data-table :search="search"
        :headers="headers"
        :items="lista_historico" 
        :items-per-page="30" :footer-props="{
          'items-per-page-options': [30, 40, 50],
          'items-per-page-text':'Registro por página'
        }"
        class="elevation-0">

                  <template v-slot:item="row">
                    <tr>
                      <td style="width: 6%;font-size:14px;">{{row.item.documento}}</td>
                      <td style="width: 25%;font-size:14px;">{{row.item.titulo}}</td>
                      <td style="width: 20%;font-size:14px;">
                        <v-chip 
                        :color="getStatus(row.item.empresa)"
                        text-color="white">
                            {{row.item.empresa}}                    
                            
                        </v-chip>  
                      </td>
                      <td style="width: 10%;font-size:14px;">{{row.item.data_insert}} </td>
                      <td style="width: 10%;font-size:14px;">{{row.item.user}} </td>
                      <td style="width: 10%;font-size:14px;font-weight:bold">{{row.item.acao}} </td>

                    </tr>
                </template>
      </v-data-table>

  </v-card>

</base-material-card>
  </v-container>
</template>
<script>
  import Documento from '../../services/documento'

  export default {
    name: 'Historico',

    mounted() {
      Documento.listahistorico().then(response => {
                if(response.status = 200){
                  this.lista_historico = response.data;
                }
              }).catch(e => {
              }).finally(() => {
            });

    },

    data () {
      return {
        headers: [
          { text: 'Nº doc', value: 'documento' },
          { text: 'Titulo', value: 'titulo' },
          { text: 'Empresa', value: 'empresa' },
          { text: 'Data', value: 'data_insert' },
          { text: 'Usuario', value: 'user' },
          { text: 'Acão', value: 'acao' }

        ],
        lista_historico: [],
        search: '',

         components: {
          DashboardCoreView: () => import('../dashboard/components/core/View'),
          },

        }
    },

   
    methods: {
     
     

        clear () {
          this.$refs.form.reset();
        },

        getStatus (status) {

          switch (status) {
            case 'Grupo Parque das Flores': return 'blue-grey darken-2'
           case 'Memorial Parque Maceió' : return 'green lighten-1'
           case 'Cemitério Parque das Flores' : return 'orange lighten-1'
           case 'Previda - Previparq' : return 'blue lighten-1'
           case 'Cemitério Previda - VERSAN' : return 'cyan lighten-1'
           case 'GPF Corretora de Seguros' : return 'purple lighten-1'
           case 'A Flor do Café - Melro & Sampaio' : return 'red lighten-1'
           case 'Cemitério Parque do Agreste - AM' : return 'indigo lighten-1'
           case 'Cemitério Reserva da Paz' : return ' pink darken-4 lighten-1'
            default: 'success'
          }
        }

     


     
    },
  }
</script>
